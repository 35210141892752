
import Vue from "vue";
import Pagination from "@/components/Pagination.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FollowersModal",
  components: {
    Pagination
  },
  props: {
    isFollowingModal: {
      type: Boolean
    },
    title: {
      type: String
    },
    data: {
      type: Object
    },
    isCurrentUser: Boolean,
    id: Number
  },
  data() {
    return {
      tabs: [
        {
          name: "All",
          type: "all"
        },
        {
          name: "Most Recent",
          type: "recent"
        },
        {
          name: "Mentors Only",
          type: "mentor"
        }
      ],
      activeTab: "all",
      filters: {
        search: "",
        filter: "all"
      },
      hoverFollowingBtn: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    onTab(type: string) {
      this.activeTab = this.filters.filter = type;
      this.search();
    },
    search() {
      this.$emit("filter", this.title, this.filters);
    },
    getDispatcher() {
      const name = this.isFollowingModal ? "FOLLOWINGS" : "FOLLOWERS";
      return `follow/GET_${name}`;
    },
    getMeta() {
      return this.isCurrentUser ? null : { id: this.id };
    },
    followingBtnHover(i: number) {
      this.hoverFollowingBtn = !this.hoverFollowingBtn;
      // @ts-ignore
      this.$refs[`following-button-${i}`][0].innerText = this.hoverFollowingBtn
        ? "Unfollow"
        : "Following";
    },
    onFollow(id: number) {
      this.$store
        .dispatch("follow/FOLLOW", {
          type: this.isFollowingModal ? "followings" : "followers",
          follower_id: id
        })
        .then(() => {
          this.$emit("update");
        });
    },
    onUnfollow(id: number) {
      this.$store
        .dispatch("follow/UNFOLLOW", {
          type: this.isFollowingModal ? "followings" : "followers",
          follower_id: id
        })
        .then(() => {
          this.$emit("update");
        });
    },
    onFollowOnAnotherProfile(id: number) {
      this.$store
        .dispatch("follow/FOLLOW", {
          type: "followings",
          follower_id: id,
          currentUserType: this.isFollowingModal ? "followings" : "followers"
        })
        .then(() => {
          this.$emit("update");
        });
    },
    onUnfollowOnAnotherProfile(id: number) {
      this.$store
        .dispatch("follow/UNFOLLOW", {
          type: "followings",
          follower_id: id,
          currentUserType: this.isFollowingModal ? "followings" : "followers"
        })
        .then(() => {
          this.$emit("update");
        });
    },
    getRoute(type: any, id: any) {
      return type === "mentor" ? `/mentors/${id}` : `/mentorees/${id}`;
    }
  }
});
