
import Vue from "vue";
import UserDetailsModal from "@/components/Modals/UserDetailsModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileInfo",
  components: {
    UserDetailsModal
  },
  props: {
    profile: {
      type: Object
    }
  },
  data() {
    return {
      categories: [
        {
          name: (this as any).$t("profile.categoriesExpertise"),
          icon: "expertise"
        },
        {
          name: (this as any).$t("profile.categoriesTitle"),
          icon: "education"
        },
        {
          name: (this as any).$t("profile.categoriesInstitution"),
          icon: "institution-main"
        }
      ],
      infoData: {
        location: {
          label: "",
          icon: "marker"
        },
        timezone_name: {
          label: "",
          icon: "time-zone"
        },
        date_since: {
          label: (this as any).$t("profile.categoriesinfoDataMemberSince"),
          icon: "member",
          selected: true
        },
        avg_response_time: {
          label: (this as any).$t("profile.categoriesinfoDataResponseTime"),
          icon: "time-circle"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      loggedProfile: "profile/getProfile"
    })
  },
  methods: {
    checkIsCurrentUser() {
      return this.loggedProfile.id == this.profile.id;
    },
    getCategoryType(key: string): any {
      const data = {
        label: "",
        counts: 0
      };
      if (this.profile.category_types && this.profile.category_types[key]) {
        // @ts-ignore
        data.label = this.profile.category_types[key][0];
        // @ts-ignore
        data.counts =
          this.profile.category_types[key].length > 1
            ? `(+${this.profile.category_types[key].length})`
            : 0;
      }

      return data;
    }
  }
});
