
import Vue from "vue";
import ProfileStatistics from "./ProfileStatistics.vue";
import ProfileActions from "./ProfileActions.vue";
import ProfileInfo from "./ProfileInfo.vue";
import ProfileSocial from "./ProfileSocial.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileData",
  components: {
    ProfileStatistics,
    ProfileActions,
    ProfileInfo,
    ProfileSocial
  },
  props: {
    profile: {
      type: Object
    },
    isCurrentUser: Boolean,
    isUserFollowed: Boolean
  },
  data() {
    return {
      isFollowed: this.isUserFollowed
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    }
  },
  async mounted() {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    await console.log("profile", this.profile);
  },
  methods: {
    refresh() {
      this.$emit("refresh");

      if ((this as any).$refs["ProfileStatistics"]) {
        (this as any).$refs["ProfileStatistics"].getFollowers();
        (this as any).$refs["ProfileStatistics"].getFollowings();
      }
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    }
  }
});
