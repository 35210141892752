
import Vue from "vue";
import FollowersModal from "@/components/Modals/FollowersModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileStatistics",
  components: {
    FollowersModal
  },
  props: {
    profile: {
      type: Object
    },
    isCurrentUser: Boolean,
    isUserDeleted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      followings: "follow/getFollowings",
      followers: "follow/getFollowers"
    })
  },
  mounted(): void {
    this.getFollowers();
    this.getFollowings();
  },
  methods: {
    updateProfile() {
      this.$store.dispatch("profile/GET_PROFILE");
    },
    searchFollowers(type: string, filters: object) {
      type === "Followers"
        ? this.getFollowers(filters)
        : this.getFollowings(filters);
    },
    getFollowers(filters = {}) {
      const id = this.isCurrentUser ? null : { id: this.profile.id };

      this.$store.dispatch("follow/GET_FOLLOWERS", {
        ...id,
        data: { ...filters },
        page: 1
      });
    },
    getFollowings(filters = {}) {
      const id = this.isCurrentUser ? null : { id: this.profile.id };

      this.$store.dispatch("follow/GET_FOLLOWINGS", {
        ...id,
        data: { ...filters },
        page: 1
      });
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    }
  }
});
