
import Vue from "vue";

export default Vue.extend({
  name: "ProfileActions",
  props: {
    profile: Object,
    isCurrentUser: Boolean,
    isUserFollowed: Boolean
  },
  data() {
    return {
      rating: 9.4,
      watchIsFollowing: this.isUserFollowed
    };
  },
  methods: {
    // Profile buttons click events
    showProfile() {
      console.log("Profile button was clicked!");
    },
    showMessages() {
      this.$router.push({ path: `/conversations/${this.profile.uuid}` });
    },
    showStatistics() {
      if (this.isCurrentUser) {
        this.$router.push({ path: `/statistics` });
      } else {
        if (this.profile.type === "mentor") {
          this.$router.push({
            path: `/mentors/${this.profile.uuid}/statistics`
          });
        } else {
          this.$router.push({
            path: `/mentorees/${this.profile.uuid}/statistics`
          });
        }
      }
    },
    unfollow() {
      this.$store
        .dispatch("follow/UNFOLLOW", {
          follower_id: this.profile.id,
          type: "followings"
        })
        .then(() => {
          // const dispatch = this.profile.type == 'mentor' ? 'mentor/GET_MENTOR' : "mentee/GET_MENTEE"

          // this.$store.dispatch(dispatch, {
          // 	id: this.$route.params.id
          // })
          this.$emit("refresh");
          this.watchIsFollowing = false;
        });
    },
    follow() {
      this.$store
        .dispatch("follow/FOLLOW", {
          follower_id: this.profile.id
        })
        .then(() => {
          // const dispatch = this.profile.type == 'mentor' ? 'mentor/GET_MENTOR' : "mentee/GET_MENTEE"
          //
          // this.$store.dispatch(dispatch, {
          // 	id: this.$route.params.id
          // })

          this.$emit("refresh");
          this.watchIsFollowing = true;
        });
    }
  }
});
